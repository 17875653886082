/* Start of Fonts */
@font-face {
  font-family: "coco";
  src: url("/fonts/coco-pro.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "coco-thin";
  src: url("/fonts/coco-pro-thin.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "coco-light";
  src: url("/fonts/coco-pro-light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

* {
  --main-bg-color: #414042;
  --main-app-color: white;
  --main-deg-app-color: #eb688d;
  --main-sec-app-color: #110819;
  --main-item-distance: 100px;
  --main-app-padding-left: 20px;
  --main-app-padding-right: 20px;
  --main-app-shadow-color: #5a5a5a
}
/* End of Fonts */

body {
  user-select: none;
  margin: 0;
  border-bottom: none;
  border-top: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: white;
  max-width: 100% !important;
  overflow-x: hidden;
  scrollbar-color: var(--main-app-color);
}

/* Start of Shared Styles */
.rt-rounded {
  border-right: 10px solid var(--main-app-color);
  border-top: 10px solid var(--main-app-color);
  border-bottom: 10px solid var(--main-app-color);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.rt-centered-txt {
  align-items: center;
  text-align: center;
}

.rt-glow-effect {
  box-shadow: 20px 20px 60px var(--main-deg-app-color),
    -20px -20px 60px var(--main-app-color);
}

.rt-linear-grad-bg {
  background: linear-gradient(
    145deg,
    var(--main-deg-app-color),
    var(--main-app-color)
  );
}

.rt-linear-bg {
  background: var(--main-app-color);
}
/* End of Shared Styles */

/* Start of Spacing */
.rt-std-left-padding {
  padding-left: var(--main-item-distance);
}

.rt-std-left-padding-5perc {
  padding-left: 5%;
}

.rt-std-right-padding {
  padding-right: var(--main-item-distance);
}

.rt-std-bottom-padding {
  padding-bottom: var(--main-item-distance);
}

.rt-std-top-padding {
  padding-top: var(--main-item-distance);
}

.rt-std-left-margin {
  margin-left: var(--main-item-distance);
}

.rt-std-right-margin {
  margin-right: var(--main-item-distance);
}

.rt-std-bottom-margin {
  margin-bottom: var(--main-item-distance);
}

.rt-std-top-margin {
  margin-top: var(--main-item-distance);
}
/* End of Spacing */

/* Start of buttons */
.rt-access-btn {
  display: grid;
  background-color: var(--main-sec-app-color);
  color: var(--main-app-color);
  font-size: 18px;
  font-weight: bolder;
  height: 50px;
  align-items: center;
  border: 5px solid var(--main-app-color);
  margin-right: var(--main-item-distance);
  cursor: pointer;
  text-decoration: none;
}

.rt-access-btn:hover {
  box-shadow: 20px 20px 60px var(--main-deg-app-color),
    -20px -20px 60px var(--main-deg-app-color);
}
/* End of buttons */

/* Start of Workarounds */
code {
  font-family: "coco";
}

.app-container {
  padding-top: 100px;
}

/* End of Workarounds */
