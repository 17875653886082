.contact-us-section-container-title-desktop {
  display: grid;
  position: relative;
  float: right;
  background-size: 400% 400%;
  background: url(/circuit.jpg);
  animation: gradient 140s ease infinite;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  border-top-left-radius: 45px;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
}

.contact-us-section-container-title-mobile {
  color: white;
  font-size: 35px;
  opacity: 0.9;
  font-weight: bolder;
  text-align: center;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.contact-us-section-item {
  padding-top: 10px;
  padding-bottom: 10px;
}

.contact-us-section-item-label {
  display: grid;
  grid-template-columns: fit-content(120px) 1fr;
  grid-column-gap: 15px;
  width: 300px;
  font-weight: bolder;
  color: var(--main-sec-app-color);
}

.contact-us-section-item-label-error {
  display: grid;
  width: 15px;
  text-align: left;
  filter: invert(27%) sepia(85%) saturate(1985%) hue-rotate(327deg)
    brightness(86%) contrast(82%);
}

.contact-us-section-item-input {
  font-size: 20px;
  margin-top: 10px;
  height: 30px;
  width: 100%;
}

.contact-us-section-item-input-mobile {
  font-size: 20px;
  margin-top: 10px;
  height: 30px;
  width: 95%;
}

.contact-us-section-item-input-free-text {
  font-size: 20px;
  margin-top: 10px;
  height: 200px;
  width: 100%;
}

.contact-us-section-item-input-free-text-mobile {
  font-size: 20px;
  margin-top: 10px;
  height: 200px;
  width: 95%;
}

.contact-us-section-send-btn {
  display: grid;
  text-align: center;
  align-items: center;
  font-weight: bolder;
  background: var(--main-app-color);
  height: 50px;
  cursor: pointer;
  min-width: 100px;
  background: var(--main-sec-app-color);
  color: var(--main-app-color);
}

.contact-us-section-send-btn:hover {
  transform: scale(1.05);
}

.contact-us-section-container-form-wrapper-desktop {
  display: grid;
  grid-template-rows: 1fr 100px;
}

.contact-us-section-container-title-inner-message {
  position: absolute;
  left: var(--main-item-distance);
  bottom: var(--main-item-distance);
  color: var(--main-deg-app-color);
  background: var(--main-app-color);
  padding: 50px;
  border-radius: 50px;
  border-bottom-left-radius: 0;
}

.contact-us-section-container-title-inner-message:hover {
  transform: scale(1.05);
  box-shadow: 20px 20px 60px var(--main-app-color),
    -20px -20px 60px var(--main-app-color);
}

.rts-page-loading {
  position: sticky;
  left: 50%;
  top: 50%;
  height: 100%;
  margin-left: calc(50% - 32px);
}
.left-pane {
  color: var(--main-deg-app-color);
  font-size: 35px;
  opacity: 0.9;
  font-weight: bolder;
  padding-top: 50px;
  padding-bottom: 50px;
}
